<template>
  <div v-if="isShow">
    <loading/>
  </div>
  <div class="common-bg ">
    <div class="middle">
      <div class="news-info common-padding-half">
        <h1>{{ details.title }}</h1>
        <div class="date">
          <i class="iconfont icon-rili"></i> {{ details.date }}
        </div>

        <div class="editor">
          <Editor :content="details.details"></Editor>
        </div>

      </div>


    </div>
</div>
</template>

<script>
import Loading from "@/components/loading";
import Editor from "@/components/Editor";
import {getNewsMore} from "@/network/main";

export default {
  name: "NewsDetails",
  components:{
    Loading,
    Editor
  },
  data() {
    return {
      id:null,
      isShow:true,
      details:'',
    }
  },
  created() {
    this.id = this.$route.params.id; //拿到id
    this.getNewsMore(this.id)
  },
  methods:{
    getNewsMore(id) {
      getNewsMore(id).then((res) => {
        this.isShow = false;
        this.details = res.data.data;
      });
    },
  }


}
</script>

<style scoped>

</style>